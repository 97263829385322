<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.clientId"
              :options="codes.clients"
              placeholder="제약사"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:50px;"
            />
            <b-form-datepicker
              v-model="query.startDate"
              class="el-def"
              placeholder="게시기간(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.endDate"
              class="el-def"
              placeholder="게시기간(To)"
            />
          </b-col>
        </b-row>
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="query.isMoaView"
              :options="codes.useTypeFixed"
              placeholder="모아보기"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:50px;"
            />
            <v-select
              v-model="query.isInfoPopUp"
              :options="codes.useTypeFixed"
              placeholder="처방 팝업 알림"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px; margin-right:30px;"
            />
            <v-select
              v-model="query.surveyStatus"
              :options="codes.surveyStatus"
              placeholder="설문 상태"
              :reduce="option => option.code"
              class="el-def"
              style="width:200px;"
            />
            <b-form-input
              v-model="query.filterStr"
              placeholder="제목 검색"
              class="el-def"
              style="width:300px;"
              @keyup.enter="refetchData()"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData()"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              설문조사 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-dark"
              class="btn-search"
              @click.prevent="excelDownload()"
            >
              <feather-icon
                icon="DownloadIcon"
                class="mr-50"
              />
              <span>다운로드</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        primary-key="id"
        :items="surveyList"
        :fields="tableColumns"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        @row-selected="onRowSelectedSurvey"
      >
        <template #cell(clientName)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(isMoaView)="data">
          <span class="text-nowrap">
            {{ data.value ? 'O' : 'X' }}
          </span>
        </template>
        <template #cell(isInfoPopUp)="data">
          <span class="text-nowrap">
            {{ data.value ? 'O' : 'X' }}
          </span>
        </template>
        <template #cell(startDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD') }}
            ~
            {{ data.item.endDate | $dateFormatter('YYYY-MM-DD') }}
          </span>
        </template>
        <template #cell(accessCode)="data">
          <span class="text-nowrap">
            {{ data.value }}
          </span>
        </template>
        <template #cell(liveStatusName)="data">
          <span class="text-nowrap">
            설문 {{ data.value }}
          </span>
        </template>
      </b-table>
    </b-card>
    <b-card
      class="mt-2"
    >
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start"
        >
          <feather-icon
            icon="EditIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            상세
          </h4>
        </b-col>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-end"
        >
          <b-button
            variant="outline-primary"
            @click.prevent="resetSurveyData()"
          >
            <feather-icon
              icon="PlusSquareIcon"
              class="mr-50"
            />
            <span>신규 등록</span>
          </b-button>
          <b-button
            variant="primary"
            class="ml-1"
            @click.prevent="saveSurvey()"
          >
            <feather-icon
              icon="SaveIcon"
              class="mr-50"
            />
            <span>저장</span>
          </b-button>
          <b-button
            variant="outline-secondary"
            class="ml-1"
            @click.prevent="removeSurvey()"
          >
            <feather-icon
              icon="Trash2Icon"
              class="mr-50"
            />
            <span>삭제</span>
          </b-button>
        </b-col>
      </b-row>
      <validation-observer ref="formRules">
        <b-row class="mt-1">
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="제약사"
              label-for="survey-client-id"
            >
              <validation-provider
                #default="{ errors }"
                name="제약사"
                rules="required"
              >
                <v-select
                  id="survey-client-id"
                  v-model="surveyData.clientId"
                  :options="codes.clients"
                  placeholder="제약사"
                  :reduce="option => option.code"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <b-form-group
              label="게시여부"
              label-for="liveinfo-is-use"
            >
              <b-form-radio-group
                id="liveinfo-is-use"
                v-model="surveyData.isUse"
                :options="codes.useTypeFixed"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              label="제목"
              label-for="liveinfo-title"
            >
              <validation-provider
                #default="{ errors }"
                name="제목"
                rules="required"
              >
                <b-form-input
                  id="liveinfo-title"
                  v-model="surveyData.title"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="9"
          >
            <b-form-group
              label="설문조사 말머리"
              label-for="survey-summary"
            >
              <validation-provider
                #default="{ errors }"
                name="설문조사 말머리"
                :rules="{
                  required: true,
                  max: 25,
                }"
              >
                <b-form-input
                  id="survey-summary"
                  v-model="surveyData.summary"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="8"
            md="7"
          >
            <b-form-group
              label="설문지 선택"
              label-for="survey-paper-title"
            >
              <validation-provider
                #default="{ errors, validate }"
                name="설문지"
                rules="required"
              >
                <b-form-input
                  id="survey-paper-title"
                  :state="errors.length > 0 ? false : null"
                  :value="surveyData.surveyPaperId ? `(${surveyData.surveyPaperId}) ${surveyData.surveyPaperTitle}` : ''"
                  readonly
                  @input="validate"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            md="2"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.prevent="showSurveyPaperSelect = true"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>설문지 검색</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-center"
          >
            <b-form-group
              label="중복 참여 기준"
              label-for="survey-allow-join-type"
            >
              <b-form-radio-group
                id="survey-allow-join-type"
                v-model="surveyData.allowJoinType"
                :options="codes.allowJoinType"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="목표인원"
              label-for="survey-target-people-num"
            >
              <validation-provider
                #default="{ errors }"
                name="목표인원"
                rules="required"
              >
                <b-form-input
                  id="survey-target-people-num"
                  v-model="surveyData.targetPeopleNum"
                  :state="errors.length > 0 ? false : null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center"
          >
            <b-form-group
              label="설문 목표인원 표시여부"
              label-for="survey-is-auto-close"
            >
              <b-form-radio-group
                id="survey-is-auto-close-disp"
                v-model="surveyData.isAutoCloseDisp"
              >
                <b-form-radio :value="true">
                  표시
                </b-form-radio>
                <b-form-radio :value="false">
                  표시안함
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center"
          >
            <b-form-group
              label="자동마감"
              label-for="survey-is-auto-close"
            >
              <b-form-radio-group
                id="survey-is-auto-close"
                v-model="surveyData.isAutoClose"
                :options="codes.useTypeFixed"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
            <b-form-group
              label="마감여부"
              label-for="end-date"
              style="margin-top: 7px; margin-left: 10px;"
            >
              <h5>
                {{ surveyData.isClose ? '마감완료(참여불가)' : '마감전(참여가능)' }}
              </h5>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="보상 포인트"
              label-for="survey-reward-point"
            >
              <validation-provider
                #default="{ errors }"
                name="보상 포인트"
                rules="required"
              >
                <cleave
                  id="survey-reward-point"
                  v-model="surveyData.rewardPoint"
                  placeholder="숫자만 입력 가능"
                  :options="cleave.number"
                  :readonly="choicePoint !== 1"
                  :state="errors.length > 0 ? false : null"
                  class="form-control"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              label="포인트 선택"
              label-for="survey-point-choice"
            >
              <v-select
                id="survey-point-choice"
                v-model="choicePoint"
                :options="codes.pointUnit"
                placeholder="포인트 선택"
                :reduce="option => option.code"
                style="width:300px"
                @input="changePointUnit()"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-center"
          >
            <b-form-group
              label="처방 대상자에게만 노출"
              label-for="survey-is-cond-target"
            >
              <b-form-radio-group
                id="survey-is-cond-target"
                v-model="surveyData.isCondTarget"
                :options="codes.useTypeFixed"
                value-field="code"
                text-field="label"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="6"
            md="3"
          >
            <b-form-group
              label="기프티콘 상품명"
              label-for="survey-reward-point"
            >
              <b-form-input
                v-model="surveyData.rewardGoodsName"
                placeholder="선택 없음"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="6"
            md="6"
          >
            <b-form-group
              label="기프티콘 선택"
              label-for="survey-reward-goods-code"
            >
              <validation-provider
                #default="{ errors }"
                name="설문보상기프티콘"
                rules="required"
              >
                <v-select
                  id="survey-reward-goods-code"
                  v-model="surveyData.rewardGoodsCode"
                  :options="codes.rewardGoods"
                  placeholder="기프티콘 선택"
                  :reduce="option => option.code"
                  style="width:300px"
                  :state="errors.length > 0 ? false : null"
                  @input="changeRewardGoods()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            cols="8"
            md="7"
          >
            <b-form-group
              label="참여 제한 대상 설문 선택"
              label-for="survey-paper-title"
            >
              <b-form-input
                id="survey-paper-title"
                :value="surveyData.surveyRestrictionTargetIds ? surveyData.surveyRestrictionTargetIds.join(', ') : ''"
                readonly
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="4"
            md="5"
            class="mb-50"
          >
            <b-button
              variant="outline-primary"
              class="mt-0 mt-md-2"
              @click.stop="showSurveyRestrictionSelect = true"
            >
              <feather-icon
                icon="SearchIcon"
                class="mr-50"
              />
              <span>설문조사 검색</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="mt-0 mt-md-2 ml-1"
              @click.stop="clearSurveyRestrictionTarget"
            >
              <feather-icon
                icon="RotateCcwIcon"
                class="mr-50"
              />
              <span>선택 초기화</span>
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-card>
    <live-info-form
      :liveinfo-id="surveyData.liveInfoId"
      :clear-flag="liveInfoFormClearFlag"
      :valid-flag="liveInfoFormValidFlag"
      :content-type="3"
      @callback="liveInfoForm.callback"
    />
    <survey-paper-select
      :show-survey-paper-select="showSurveyPaperSelect"
      @close="surveyPaperSelect.close"
    />
    <survey-restriction-target-select
      :show-survey-restriction-select="showSurveyRestrictionSelect"
      @close="surveyRestrictionTargetSelect.close"
    />

  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import { saveAs } from "file-saver"
import { ref, onMounted, getCurrentInstance } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, length } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { errorFormatter } from '@core/utils/filter'
import { join } from 'lodash'
import LiveInfoForm from '../LiveInfoForm.vue'
import SurveyPaperSelect from './SurveyPaperSelect.vue'
import SurveyRestrictionTargetSelect from './SurveyRestrictionTargetSelect.vue'

export default {
  components: {
    vSelect,
    Cleave,
    LiveInfoForm,
    SurveyPaperSelect,
    SurveyRestrictionTargetSelect,
    ValidationProvider,
    ValidationObserver,
  },

  setup() {
    onMounted(() => {
      fetchCodes()
    })
    const toast = useToast()
    const instance = getCurrentInstance()
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs
    const pushToast = (variant, title) => {
      let icon
      if (variant === 'success') {
        icon = 'CheckCircleIcon'
      } else {
        icon = 'AlertTriangleIcon'
      }
      toast({
        component: ToastificationContent,
        props: { title, icon, variant },
      })
    }

    const cleave = ref({
      number: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
      },
    })

    const surveyInitState = {
      liveInfoId: null,
      title: null,
      isUse: true,
      isClose: false,
      surveyPaperId: null,
      surveyPaperTitle: null,
      clientId: null,
      summary: null,
      postEndDate: null,
      rewardPoint: null,
      targetPeopleNum: null,
      isAutoClose: true,
      isAutoCloseDisp: true,
      allowJoinType: 1,
      isCondTarget: false,
      rewardGoodsCode: null,
      rewardGoodsName: null,
      surveyRestrictionTargetIds: [],
    }
    const surveyData = ref({ ...surveyInitState })

    const resetSurveyData = () => {
      choicePoint.value = null
      Object.assign(surveyData.value, surveyInitState)

      liveInfoFormClearFlag.value = !liveInfoFormClearFlag.value
    }

    // Set Codes
    const codes = ref({
      useTypeFixed: [
        { label: '사용', code: true },
        { label: '사용안함', code: false },
      ],
      allowJoinType: [],
      surveyStatus: [],
      clients: [],
      pointUnit: [
        { label: '직접입력', code: 1 },
        { label: '5천포인트(5,000P)', code: 5000 },
        { label: '1만포인트(10,000P)', code: 10000 },
        { label: '2만포인트(20,000P)', code: 20000 },
      ],
      rewardGoods: [],
    })

    const choicePoint = ref(null)
    const changePointUnit = () => {
      if (choicePoint.value === 1) {
        surveyData.value.rewardPoint = null
      } else {
        surveyData.value.rewardPoint = choicePoint.value
      }
    }

    const changeRewardGoods = () => {
      if (!surveyData.value.rewardGoodsCode) {
        surveyData.value.rewardGoodsName = null
      } else {
        const select = codes.value.rewardGoods.find(x => x.code === surveyData.value.rewardGoodsCode)
        surveyData.value.rewardGoodsName = select.label
      }
    }

    const fetchCodes = () => {
      axios.get('/fa/survey/codes')
        .then(rs => {
          const {
            allowJoinType,
            surveyLiveStatus,
            clients,
          } = rs.data
          codes.value.allowJoinType = allowJoinType
          codes.value.surveyStatus = surveyLiveStatus
          codes.value.clients = clients

          refetchData()
          fetchRewardGoods()
        })
        .catch(() => {
          pushToast('danger', '코드 데이터를 불러오는데 실패하였습니다.')
        })
    }

    const fetchRewardGoods = () => {
      axios.get('/fa/survey/reward-goods')
        .then(rs => {
          const rewardGoods = rs.data
          codes.value.rewardGoods = rewardGoods
        })
        .catch(() => {
          pushToast('danger', '설문 전용 기프티콘 목록을 불러오는데 실패하였습니다.')
        })
    }
    const delMsg = '삭제된 기프티콘입니다.'
    // Set Codes End.

    // Query Data
    const query = ref({
      clientId: null,
      startDate: dayjs().add(-6, 'month').format('YYYY-MM-DD'),
      endDate: dayjs().add(2, 'month').format('YYYY-MM-DD'),
      isMoaView: null,
      isInfoPopUp: null,
      surveyStatus: null,
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const surveyList = ref([])
    const totalRecords = ref(0)
    const tableColumns = [
      {
        key: 'liveInfoId', label: 'Id', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'clientName', label: '제약사명', sortable: true, thStyle: { width: '12%' },
      },
      { key: 'title', label: '제목', sortable: true },
      {
        key: 'isMoaView', label: '모아보기', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'isInfoPopUp', label: '처방 팝업', sortable: true, thStyle: { width: '7%' },
      },
      {
        key: 'startDate', label: '게시 기간', sortable: true, thStyle: { width: '15%' },
      },
      {
        key: 'accessCode', label: '접속코드', sortable: true, thStyle: { width: '12%' },
      },
      {
        key: 'liveStatusName', label: '설문 상태', sortable: true, thStyle: { width: '10%' },
      },
    ]
    const refetchData = () => {
      fetchSurveys()
    }
    const onRowSelectedSurvey = items => {
      const item = items[0]
      if (item) {
        fetchSurvey(item.liveInfoId)
      }
    }
    // Main Table Config End.

    const showSurveyPaperSelect = ref(false)
    const surveyPaperSelect = {
      close: rs => {
        showSurveyPaperSelect.value = false
        if (rs) {
          surveyData.value.surveyPaperId = rs.id
          surveyData.value.surveyPaperTitle = rs.title
        }
      },
    }

    const showSurveyRestrictionSelect = ref(false)
    const surveyRestrictionTargetSelect = {
      close: rs => {
        showSurveyRestrictionSelect.value = false
        if (rs) {
          surveyData.value.surveyRestrictionTargetIds = [...new Set([...surveyData.value.surveyRestrictionTargetIds, ...rs])]
        }
      },
    }

    const clearSurveyRestrictionTarget = () => {
      surveyData.value.surveyRestrictionTargetIds = []
    }

    const liveInfoFormValidFlag = ref(false)
    const liveInfoFormClearFlag = ref(false)
    const liveInfoForm = {
      callback: async rs => {
        // rs는 liveInfoForm의 결과데이터로 Validation이 실패하면 null, 성공 시 데이터 객체 반환
        if (!rs) return

        const survey = surveyData.value
        const liveInfo = rs

        if (!liveInfo.isTopFix) { // topfix를 사용하지 않으면 순위는 0으로 고정
          liveInfo.topFixLevel = 0
        }

        let isTopFixedAllow = false
        if (liveInfo.isMoaView && liveInfo.isTopFix) {
          await axios.get(`/fa/survey/checkTopFixed/${liveInfo.topFixLevel}`)
            .then(result => {
              if (!result.data) {
                isTopFixedAllow = true // 데이터 없을시 허용
                return
              }
              if (result.data.liveInfoId === survey.liveInfoId) {
                isTopFixedAllow = true // 같은 라이브인포 업데이트 시 허용
              } else {
                pushToast(
                  'danger', `같은 상단 고정값을 사용하는 다른 약품정보는 허용되지 않습니다.\n\n 
                  제목 = ${result.data.liveInfo.title}`,
                )
                isTopFixedAllow = false // 그외 불허용
              }
            })
            .catch(() => {
              pushToast('danger', '상단 고정 조회에 실패하였습니다.')
            })

          if (!isTopFixedAllow) {
            return
          }
        }

        const duplicateNoticeTimes = new Set()
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < liveInfo.schedules.length; i++) {
          if (liveInfo.schedules[i].noticeTime !== null) {
            const currentNoticeTime = liveInfo.schedules[i].noticeTime.substr(0, 5)
            if (duplicateNoticeTimes.has(currentNoticeTime) && (liveInfo.isScheduleUse || !liveInfo.isImmediate)) {
              pushToast('danger', '스케줄,처방알림 시간에 중복이 있습니다.')
              return
            }
            duplicateNoticeTimes.add(currentNoticeTime)
          }
        }

        liveInfo.title = survey.title
        liveInfo.isUse = survey.isUse
        liveInfo.contentType = 3 // Survey

        survey.liveInfo = liveInfo

        const { liveInfoId } = survey
        if (liveInfoId) {
          updateSurvey()
        } else {
          saveNewSurvey()
        }
      },
    }

    const fetchSurvey = id => {
      axios.get(`/fa/survey/${id}`)
        .then(rs => {
          const { data } = rs
          data.title = data.liveInfo.title
          data.isUse = data.liveInfo.isUse
          surveyData.value = data

          // 기프티콘 코드가 삭제된 경우 Code 대신 삭제된 기프티콘 문구 노출
          if (!codes.value.rewardGoods.some(op => op.code === surveyData.value.rewardGoodsCode)) {
            surveyData.value.rewardGoodsCode = delMsg
          }
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const fetchSurveys = () => {
      axios.post('/fa/survey/list', {
        search: {
          clientId: query.value.clientId,
          startDate: query.value.startDate ? `${query.value.startDate}T00:00:00` : null,
          endDate: query.value.endDate ? `${query.value.endDate}T23:59:59` : null,
          isMoaView: query.value.isMoaView,
          isInfoPopUp: query.value.isInfoPopUp,
          surveyStatus: query.value.surveyStatus,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: 'liveInfoId',
          reverse: true,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          surveyList.value = items
          totalRecords.value = totalRecord
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터 조회에 실패하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const excelDownload = () => {
      axios.post('/fa/survey/list-excel', {
        search: {
          clientId: query.value.clientId,
          startDate: query.value.startDate ? `${query.value.startDate}T00:00:00` : null,
          endDate: query.value.endDate ? `${query.value.endDate}T23:59:59` : null,
          isMoaView: query.value.isMoaView,
          isInfoPopUp: query.value.isInfoPopUp,
          surveyStatus: query.value.surveyStatus,
          filterStr: query.value.filterStr,
        },
      }, { responseType: 'blob' })
        .then(rs => {
          const blob = new Blob([rs.data])
          const fileName = '설문조사_리스트'

          saveAs(blob, `${fileName}.xlsx`)
        })
        .catch(() => {
        })
    }

    const saveSurvey = () => {
      const validPromise = refs.formRules.validate()
      validPromise.then(valid => {
        if (!valid) return

        // LiveInfo Form Validation Callback
        liveInfoFormValidFlag.value = !liveInfoFormValidFlag.value
      })
    }

    const saveNewSurvey = () => {
      axios.put('/fa/survey/new', surveyData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '신규 Survey 등록 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const updateSurvey = () => {
      axios.patch('/fa/survey/update', surveyData.value)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 변경 완료',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          refetchData()
        })
        .catch(error => {
          toast({
            component: ToastificationContent,
            props: {
              title: errorFormatter(error, '데이터를 저장하는 과정에서 오류가 발생하였습니다.'),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removeSurvey = () => {
      if (!surveyData.value.liveInfoId) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/survey/${surveyData.value.liveInfoId}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
              resetSurveyData()
            })
            .catch(error => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(error, '삭제하는 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    return {
      cleave,
      query,
      codes,
      choicePoint,
      changePointUnit,
      changeRewardGoods,
      surveyData,
      surveyList,
      tableColumns,
      length,
      required,
      refetchData,
      totalRecords,
      fetchSurveys,
      resetSurveyData,
      onRowSelectedSurvey,
      saveSurvey,
      removeSurvey,
      liveInfoForm,
      liveInfoFormValidFlag,
      liveInfoFormClearFlag,

      showSurveyPaperSelect,
      surveyPaperSelect,

      showSurveyRestrictionSelect,
      surveyRestrictionTargetSelect,
      clearSurveyRestrictionTarget,

      excelDownload,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
