var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-card", { staticClass: "liveinfo-form", attrs: { "no-body": "" } }, [
        _c(
          "div",
          { staticClass: "m-2" },
          [
            _c(
              "b-row",
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px", "margin-right": "50px" },
                      attrs: {
                        options: _vm.codes.clients,
                        placeholder: "제약사",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.clientId,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "clientId", $$v)
                        },
                        expression: "query.clientId",
                      },
                    }),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "게시기간(Fr)" },
                      model: {
                        value: _vm.query.startDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "startDate", $$v)
                        },
                        expression: "query.startDate",
                      },
                    }),
                    _c("label", { staticClass: "ml-25 mr-50" }, [_vm._v("~")]),
                    _c("b-form-datepicker", {
                      staticClass: "el-def",
                      attrs: { placeholder: "게시기간(To)" },
                      model: {
                        value: _vm.query.endDate,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "endDate", $$v)
                        },
                        expression: "query.endDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-row",
              { staticClass: "mt-1" },
              [
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                    attrs: { cols: "12", md: "10" },
                  },
                  [
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px", "margin-right": "50px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "모아보기",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isMoaView,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isMoaView", $$v)
                        },
                        expression: "query.isMoaView",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px", "margin-right": "30px" },
                      attrs: {
                        options: _vm.codes.useTypeFixed,
                        placeholder: "처방 팝업 알림",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.isInfoPopUp,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "isInfoPopUp", $$v)
                        },
                        expression: "query.isInfoPopUp",
                      },
                    }),
                    _c("v-select", {
                      staticClass: "el-def",
                      staticStyle: { width: "200px" },
                      attrs: {
                        options: _vm.codes.surveyStatus,
                        placeholder: "설문 상태",
                        reduce: function (option) {
                          return option.code
                        },
                      },
                      model: {
                        value: _vm.query.surveyStatus,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "surveyStatus", $$v)
                        },
                        expression: "query.surveyStatus",
                      },
                    }),
                    _c("b-form-input", {
                      staticClass: "el-def",
                      staticStyle: { width: "300px" },
                      attrs: { placeholder: "제목 검색" },
                      on: {
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.refetchData()
                        },
                      },
                      model: {
                        value: _vm.query.filterStr,
                        callback: function ($$v) {
                          _vm.$set(_vm.query, "filterStr", $$v)
                        },
                        expression: "query.filterStr",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  {
                    staticClass:
                      "d-flex align-items-center justify-content-end",
                    attrs: { cols: "12", md: "2" },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        staticClass: "btn-search",
                        attrs: { variant: "primary" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.refetchData()
                          },
                        },
                      },
                      [_vm._v(" 조회 ")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "div",
            { staticClass: "m-2" },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-start",
                      attrs: { md: "6" },
                    },
                    [
                      _c("h5", { staticClass: "mb-0 ml-50" }, [
                        _vm._v(
                          " 설문조사 리스트 [" +
                            _vm._s(_vm.totalRecords) +
                            "] 건 "
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-end",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "btn-search",
                          attrs: { variant: "outline-dark" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.excelDownload()
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "DownloadIcon" },
                          }),
                          _c("span", [_vm._v("다운로드")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            staticClass: "position-relative",
            attrs: {
              "primary-key": "id",
              items: _vm.surveyList,
              fields: _vm.tableColumns,
              responsive: "",
              "sticky-header": "",
              selectable: "",
              "select-mode": "single",
            },
            on: { "row-selected": _vm.onRowSelectedSurvey },
            scopedSlots: _vm._u([
              {
                key: "cell(clientName)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          overflow: "hidden",
                          "text-overflow": "ellipsis",
                          "white-space": "nowrap",
                        },
                      },
                      [_vm._v(" " + _vm._s(data.value) + " ")]
                    ),
                  ]
                },
              },
              {
                key: "cell(isMoaView)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value ? "O" : "X") + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(isInfoPopUp)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value ? "O" : "X") + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(startDate)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm._f("$dateFormatter")(data.value, "YYYY-MM-DD")
                          ) +
                          " ~ " +
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              data.item.endDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                          " "
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "cell(accessCode)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" " + _vm._s(data.value) + " "),
                    ]),
                  ]
                },
              },
              {
                key: "cell(liveStatusName)",
                fn: function (data) {
                  return [
                    _c("span", { staticClass: "text-nowrap" }, [
                      _vm._v(" 설문 " + _vm._s(data.value) + " "),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "b-card",
        { staticClass: "mt-2" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-start",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: "EditIcon", size: "19" },
                  }),
                  _c("h4", { staticClass: "mb-0 ml-50" }, [_vm._v(" 상세 ")]),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "d-flex align-items-center justify-content-end",
                  attrs: { cols: "12", md: "6" },
                },
                [
                  _c(
                    "b-button",
                    {
                      attrs: { variant: "outline-primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.resetSurveyData()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "PlusSquareIcon" },
                      }),
                      _c("span", [_vm._v("신규 등록")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "primary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveSurvey()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "SaveIcon" },
                      }),
                      _c("span", [_vm._v("저장")]),
                    ],
                    1
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "ml-1",
                      attrs: { variant: "outline-secondary" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.removeSurvey()
                        },
                      },
                    },
                    [
                      _c("feather-icon", {
                        staticClass: "mr-50",
                        attrs: { icon: "Trash2Icon" },
                      }),
                      _c("span", [_vm._v("삭제")]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "validation-observer",
            { ref: "formRules" },
            [
              _c(
                "b-row",
                { staticClass: "mt-1" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "제약사",
                            "label-for": "survey-client-id",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제약사", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      attrs: {
                                        id: "survey-client-id",
                                        options: _vm.codes.clients,
                                        placeholder: "제약사",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.surveyData.clientId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "clientId",
                                            $$v
                                          )
                                        },
                                        expression: "surveyData.clientId",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "게시여부",
                            "label-for": "liveinfo-is-use",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "liveinfo-is-use",
                              options: _vm.codes.useTypeFixed,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.surveyData.isUse,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyData, "isUse", $$v)
                              },
                              expression: "surveyData.isUse",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "9" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "제목",
                            "label-for": "liveinfo-title",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "제목", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "liveinfo-title",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.surveyData.title,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.surveyData, "title", $$v)
                                        },
                                        expression: "surveyData.title",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "9" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "설문조사 말머리",
                            "label-for": "survey-summary",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "설문조사 말머리",
                              rules: {
                                required: true,
                                max: 25,
                              },
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "survey-summary",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.surveyData.summary,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "summary",
                                            $$v
                                          )
                                        },
                                        expression: "surveyData.summary",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8", md: "7" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "설문지 선택",
                            "label-for": "survey-paper-title",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "설문지", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "survey-paper-title",
                                        state: errors.length > 0 ? false : null,
                                        readonly: "",
                                      },
                                      model: {
                                        value: _vm.surveyData.surveyPaperTitle,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "surveyPaperTitle",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "surveyData.surveyPaperTitle",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "4", md: "2" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showSurveyPaperSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "SearchIcon" },
                          }),
                          _c("span", [_vm._v("설문지 검색")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center",
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "중복 참여 기준",
                            "label-for": "survey-allow-join-type",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "survey-allow-join-type",
                              options: _vm.codes.allowJoinType,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.surveyData.allowJoinType,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyData, "allowJoinType", $$v)
                              },
                              expression: "surveyData.allowJoinType",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "목표인원",
                            "label-for": "survey-target-people-num",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "목표인원", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("b-form-input", {
                                      attrs: {
                                        id: "survey-target-people-num",
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.surveyData.targetPeopleNum,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "targetPeopleNum",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "surveyData.targetPeopleNum",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "설문 목표인원 표시여부",
                            "label-for": "survey-is-auto-close",
                          },
                        },
                        [
                          _c(
                            "b-form-radio-group",
                            {
                              attrs: { id: "survey-is-auto-close-disp" },
                              model: {
                                value: _vm.surveyData.isAutoCloseDisp,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.surveyData,
                                    "isAutoCloseDisp",
                                    $$v
                                  )
                                },
                                expression: "surveyData.isAutoCloseDisp",
                              },
                            },
                            [
                              _c("b-form-radio", { attrs: { value: true } }, [
                                _vm._v(" 표시 "),
                              ]),
                              _c("b-form-radio", { attrs: { value: false } }, [
                                _vm._v(" 표시안함 "),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass: "d-flex align-items-center",
                      attrs: { cols: "12", md: "6" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "자동마감",
                            "label-for": "survey-is-auto-close",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "survey-is-auto-close",
                              options: _vm.codes.useTypeFixed,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.surveyData.isAutoClose,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyData, "isAutoClose", $$v)
                              },
                              expression: "surveyData.isAutoClose",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          staticStyle: {
                            "margin-top": "7px",
                            "margin-left": "10px",
                          },
                          attrs: { label: "마감여부", "label-for": "end-date" },
                        },
                        [
                          _c("h5", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.surveyData.isClose
                                    ? "마감완료(참여불가)"
                                    : "마감전(참여가능)"
                                ) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "보상 포인트",
                            "label-for": "survey-reward-point",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: { name: "보상 포인트", rules: "required" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("cleave", {
                                      staticClass: "form-control",
                                      attrs: {
                                        id: "survey-reward-point",
                                        placeholder: "숫자만 입력 가능",
                                        options: _vm.cleave.number,
                                        readonly: _vm.choicePoint !== 1,
                                        state: errors.length > 0 ? false : null,
                                      },
                                      model: {
                                        value: _vm.surveyData.rewardPoint,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "rewardPoint",
                                            $$v
                                          )
                                        },
                                        expression: "surveyData.rewardPoint",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "포인트 선택",
                            "label-for": "survey-point-choice",
                          },
                        },
                        [
                          _c("v-select", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              id: "survey-point-choice",
                              options: _vm.codes.pointUnit,
                              placeholder: "포인트 선택",
                              reduce: function (option) {
                                return option.code
                              },
                            },
                            on: {
                              input: function ($event) {
                                return _vm.changePointUnit()
                              },
                            },
                            model: {
                              value: _vm.choicePoint,
                              callback: function ($$v) {
                                _vm.choicePoint = $$v
                              },
                              expression: "choicePoint",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center",
                      attrs: { cols: "12", md: "3" },
                    },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "처방 대상자에게만 노출",
                            "label-for": "survey-is-cond-target",
                          },
                        },
                        [
                          _c("b-form-radio-group", {
                            attrs: {
                              id: "survey-is-cond-target",
                              options: _vm.codes.useTypeFixed,
                              "value-field": "code",
                              "text-field": "label",
                            },
                            model: {
                              value: _vm.surveyData.isCondTarget,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyData, "isCondTarget", $$v)
                              },
                              expression: "surveyData.isCondTarget",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "3" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "기프티콘 상품명",
                            "label-for": "survey-reward-point",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "선택 없음", disabled: "" },
                            model: {
                              value: _vm.surveyData.rewardGoodsName,
                              callback: function ($$v) {
                                _vm.$set(_vm.surveyData, "rewardGoodsName", $$v)
                              },
                              expression: "surveyData.rewardGoodsName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6", md: "6" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "기프티콘 선택",
                            "label-for": "survey-reward-goods-code",
                          },
                        },
                        [
                          _c("validation-provider", {
                            attrs: {
                              name: "설문보상기프티콘",
                              rules: "required",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (ref) {
                                  var errors = ref.errors
                                  return [
                                    _c("v-select", {
                                      staticStyle: { width: "300px" },
                                      attrs: {
                                        id: "survey-reward-goods-code",
                                        options: _vm.codes.rewardGoods,
                                        placeholder: "기프티콘 선택",
                                        reduce: function (option) {
                                          return option.code
                                        },
                                        state: errors.length > 0 ? false : null,
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.changeRewardGoods()
                                        },
                                      },
                                      model: {
                                        value: _vm.surveyData.rewardGoodsCode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.surveyData,
                                            "rewardGoodsCode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "surveyData.rewardGoodsCode",
                                      },
                                    }),
                                    _c(
                                      "small",
                                      { staticClass: "text-danger" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    ),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "8", md: "7" } },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "참여 제한 대상 설문 선택",
                            "label-for": "survey-paper-title",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "survey-paper-title",
                              value: _vm.surveyData.surveyRestrictionTargetIds
                                ? _vm.surveyData.surveyRestrictionTargetIds.join(
                                    ", "
                                  )
                                : "",
                              readonly: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "mb-50", attrs: { cols: "4", md: "5" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2",
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              _vm.showSurveyRestrictionSelect = true
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "SearchIcon" },
                          }),
                          _c("span", [_vm._v("설문조사 검색")]),
                        ],
                        1
                      ),
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-0 mt-md-2 ml-1",
                          attrs: { variant: "outline-secondary" },
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.clearSurveyRestrictionTarget($event)
                            },
                          },
                        },
                        [
                          _c("feather-icon", {
                            staticClass: "mr-50",
                            attrs: { icon: "RotateCcwIcon" },
                          }),
                          _c("span", [_vm._v("선택 초기화")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("live-info-form", {
        attrs: {
          "liveinfo-id": _vm.surveyData.liveInfoId,
          "clear-flag": _vm.liveInfoFormClearFlag,
          "valid-flag": _vm.liveInfoFormValidFlag,
          "content-type": 3,
        },
        on: { callback: _vm.liveInfoForm.callback },
      }),
      _c("survey-paper-select", {
        attrs: { "show-survey-paper-select": _vm.showSurveyPaperSelect },
        on: { close: _vm.surveyPaperSelect.close },
      }),
      _c("survey-restriction-target-select", {
        attrs: {
          "show-survey-restriction-select": _vm.showSurveyRestrictionSelect,
        },
        on: { close: _vm.surveyRestrictionTargetSelect.close },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }